import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import './index.css';
import { OidcSecure } from '@axa-fr/react-oidc';
import {
  ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import _ from 'lodash';
import { VanillaOidc } from '@axa-fr/react-oidc/dist/vanilla/vanillaOidc';
import Layout from './components/Layout';
import Landing from './components/Landing';
import Dashboard from './components/Dashboard';
import LoadingPage from './components/LoadingPage';
import {
  ImportHubspotContactListRoute,
  DashboardRoute,
  LandingRoute,
  LoadingRoute,
  SRRoute,
  SRAssetRoute,
  SRAssetCreateRoute,
  ApiSubscriptionsRoute,
  SRRouteDashboard,
  SRAssetImportRoute,
} from './components/Routes';
import ImportHubspotContactListApp from './components/modules/admin/ImportHubspotContactListApp';
import RegisteredSensorApp from './components/modules/registered-sensor/RegisteredSensorApp';
import AssetEdit from './components/modules/registered-sensor/AssetEdit';
import AssetCreate from './components/modules/registered-sensor/AssetCreate';
import ApiCatalogApp from './components/modules/api-subscriptions/ApiCatalogApp';
import MultiRegisteredSensorApp from './components/modules/registered-sensor/MultiRegisteredSensorApp';
import ImportSpreadsheet from './components/modules/registered-sensor/ImportSpreadsheet';

export default function App() {
  const getOidc = VanillaOidc.get;

  const authLink = setContext((request, { headers }) => {
    const oidc = getOidc();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${oidc.tokens?.accessToken}`,
      },
    };
  });

  const assetLink = new RestLink({
    uri: process.env.REACT_APP_ASSET_API_URL,
    fieldNameNormalizer: (key: string) => _.camelCase(key),
  });

  const servicePortalApiGraphqlLink = new HttpLink({
    uri: process.env.REACT_APP_SERVICE_PORTAL_API_URL,
  });

  const cubeGraphqlLink = new HttpLink({
    uri: process.env.REACT_APP_CUBE_API_URL,
  });

  const client = new ApolloClient({
    link: authLink.concat(ApolloLink.split(
      (operation) => operation.getContext().clientName === 'asset',
      assetLink,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'cube',
        cubeGraphqlLink,
        servicePortalApiGraphqlLink,
      ),
    )),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path={LandingRoute()} element={<Layout />}>
            <Route index element={<Landing />} />
            <Route
              path={DashboardRoute()}
              element={(
                <OidcSecure callbackPath={DashboardRoute()}>
                  <Dashboard />
                </OidcSecure>
              )}
            />

            {process.env.REACT_APP_SENSORREGISTER_ENABLED === 'true' && (
              <>
                <Route
                  path={SRRouteDashboard()}
                  element={
                    <OidcSecure><MultiRegisteredSensorApp /></OidcSecure>
                  }
                />
                <Route
                  path={SRRoute()}
                  element={
                    <OidcSecure><RegisteredSensorApp /></OidcSecure>
                  }
                />
                <Route path={SRAssetRoute()} element={<OidcSecure><AssetEdit /></OidcSecure>} />
                <Route path={SRAssetCreateRoute()} element={<OidcSecure><AssetCreate /></OidcSecure>} />
                {process.env.REACT_APP_SENSORREGISTER_IMPORT_ENABLED === 'true' && (
                  <Route path={SRAssetImportRoute()} element={<OidcSecure><ImportSpreadsheet /></OidcSecure>} />
                )}
              </>
            )}

            {process.env.REACT_APP_API_SUBSCRIPTIONS_ENABLED === 'true' && (
              <Route
                path={ApiSubscriptionsRoute()}
                element={<OidcSecure><ApiCatalogApp /></OidcSecure>}
              />
            )}

            <Route path={LoadingRoute()} element={<LoadingPage />} />

            <Route
              path={ImportHubspotContactListRoute()}
              element={<OidcSecure><ImportHubspotContactListApp /></OidcSecure>}
            />

            <Route path="*" element={<Landing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}
