import React from 'react';
import Footer from './Footer';
import dashboard1 from '../images/dashboard1.jpg';
import dashboard2 from '../images/dashboard2.jpg';
import dashboard3 from '../images/dashboard3.jpg';
import useMyRoles from './hooks/useMyRoles';
import Loader from './Loader';
import HubspotCard from './modules/admin/HubspotCard';
import RegisteredSensorCard from './modules/registered-sensor/RegisteredSensorCard';
import PlatformAlerts from '../PlatformAlerts';
import { RoleOrganization, RoleUser } from '../generated/gql/types';
import MultiRegisteredSensorCard from './modules/registered-sensor/MultiRegisteredSensorCard';

function Dashboard() {
  const myRoles = useMyRoles();

  if (!myRoles) {
    return <Loader />;
  }

  function renderSensorRegisterCards() {
    if (process.env.REACT_APP_SENSORREGISTER_ENABLED === 'true') {
      const sensorRegisterOrganizations = (myRoles?.organizationRoles ?? []).filter((r) => r.roles.find((ro) => ro === RoleOrganization.OrgSensorRegisterUser || ro === RoleOrganization.OrgSensorRegisterManager));
      if (sensorRegisterOrganizations.length > 1) {
        return (<MultiRegisteredSensorCard />);
      }
      if (sensorRegisterOrganizations.length === 1) {
        return <RegisteredSensorCard orgId={sensorRegisterOrganizations[0].orgId} />;
      }
    }

    return '';
  }

  return (
    <div className="mx-2.5 md:mx-0">
      <section className="bg-white">
        <PlatformAlerts />
        <div className="grid justify-center py-8 px-4">
          <h2 className="mb-4 text-center  text-4xl tracking-tight font-extrabold text-gray-900">
            Welkom in het WeCity serviceportaal
          </h2>
          <p className="mb-4 text-center font-light text-gray-500 sm:text-lg">
            Jouw ingang voor al onze dienstverlening
          </p>
        </div>
        <div className="grid gap-4 grid-cols-3 dbimgcont mb-6">
          <img className="w-full rounded-lg dbimg " src={dashboard1} alt="illustratie straat" />
          <img className="w-full rounded-lg dbimg " src={dashboard2} alt="illustratie bouw" />
          <img className="w-full rounded-lg dbimg " src={dashboard3} alt="illustratie tuin" />
        </div>
      </section>

      <section className="flex flex-row flex-wrap gap-4 ">
        {renderSensorRegisterCards()}

        {myRoles.userRoles.find((r) => r === RoleUser.UserGlobalAdmin) && (
          <HubspotCard />
        )}
      </section>
      <Footer />
    </div>
  );
}

export default Dashboard;
