import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** Base64 string */
  Base64: any;
  /** A date time with timezone formatted according to RFC3339 (without millis, we're not timing F1 here) */
  Instant: any;
  /** Valid JSON */
  Json: any;
};

export type Query = {
  __typename?: 'Query';
  registeredSensorAssets: AssetsResult;
  searchRegisteredSensorAssets: AssetsResult;
  registeredSensorAsset: AssetResult;
  scheme?: Maybe<SchemeResult>;
  adminAssetsForUser?: Maybe<AssetsResult>;
  adminSearchAssets: AssetsResult;
  platformAlerts: Array<PlatformAlert>;
  myOrganizationRoles: Array<OrganizationUser>;
  myUserRoles: Array<RoleUser>;
  organizations: Array<Organization>;
  organization: Organization;
  promotableDmiOrganizations: Array<DmiOrganization>;
  promotableDmiUsers: Array<DmiUser>;
  userSearch: Array<UserSummary>;
  user: User;
  dataProductSearch: Array<DataProduct>;
  hubspot: HubspotQuery;
  dataMarket: DataMarket;
  dataProductFilter: DataProductFilter;
  cube: Array<Result>;
};


export type QueryRegisteredSensorAssetsArgs = {
  filterQuery?: InputMaybe<Scalars['String']>;
};


export type QuerySearchRegisteredSensorAssetsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryRegisteredSensorAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAdminAssetsForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryAdminSearchAssetsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryUserSearchArgs = {
  query: UserSearchQuery;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryDataProductSearchArgs = {
  query: DataProductSearchQuery;
};


export type QueryCubeArgs = {
  where?: InputMaybe<RootWhereInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
  renewQuery?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<RootOrderByInput>;
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PropertyInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Property>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaginationLink = {
  __typename?: 'PaginationLink';
  url: Scalars['String'];
  active: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  currentPage: Scalars['Int'];
  from?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  to?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  links?: Maybe<Array<PaginationLink>>;
};

export type AssetsResult = {
  __typename?: 'AssetsResult';
  data?: Maybe<Array<Asset>>;
  meta?: Maybe<PaginationMeta>;
};

export type AssetResult = {
  __typename?: 'AssetResult';
  data: Asset;
};

export type PropertyResult = {
  __typename?: 'PropertyResult';
  data: Property;
};

export type PropertyType = {
  __typename?: 'PropertyType';
  name?: Maybe<Scalars['String']>;
  validation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Scheme = {
  __typename?: 'Scheme';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  propertyTypes?: Maybe<Array<PropertyType>>;
};

export type SchemeResult = {
  __typename?: 'SchemeResult';
  data?: Maybe<Scheme>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type GroupResult = {
  __typename?: 'GroupResult';
  data: Group;
};

export type Mutation = {
  __typename?: 'Mutation';
  createRegisteredSensorAsset: AssetResult;
  bulkCreateRegisteredSensorAssets: AssetsResult;
  updateRegisteredSensorAsset: AssetResult;
  updateRegisteredSensorProperty: PropertyResult;
  deleteRegisteredSensorProperty: PropertyResult;
  deleteRegisteredSensorAsset: AssetResult;
  adminDeleteAsset: AssetResult;
  adminCreateGroup: GroupResult;
  organizations: OrganizationsMutation;
  users: UsersMutation;
  hubspot: HubspotMutation;
  dataMarket: DataMarketMutation;
};


export type MutationCreateRegisteredSensorAssetArgs = {
  input: CreateAssetCommand;
};


export type MutationBulkCreateRegisteredSensorAssetsArgs = {
  input: Array<InputMaybe<CreateAssetCommand>>;
};


export type MutationUpdateRegisteredSensorAssetArgs = {
  input: UpdateAssetCommand;
};


export type MutationUpdateRegisteredSensorPropertyArgs = {
  input: UpdatePropertyCommand;
};


export type MutationDeleteRegisteredSensorPropertyArgs = {
  input: DeletePropertyCommand;
};


export type MutationDeleteRegisteredSensorAssetArgs = {
  assetId: Scalars['ID'];
};


export type MutationAdminDeleteAssetArgs = {
  assetId: Scalars['ID'];
};


export type MutationAdminCreateGroupArgs = {
  input: CreateGroupCommand;
};

export type CreateAssetCommand = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  properties?: InputMaybe<Array<PropertyInput>>;
};

export type UpdateAssetCommand = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  properties?: InputMaybe<Array<PropertyInput>>;
};

export type UpdatePropertyCommand = {
  assetId: Scalars['ID'];
  propertyId: Scalars['ID'];
  value: Scalars['String'];
};

export type CreatePropertyCommand = {
  assetId: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
  type: Scalars['String'];
};

export type DeletePropertyCommand = {
  assetId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type CreateGroupCommand = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type BulkOrganizationRolesCommand = {
  userIds: Array<Scalars['ID']>;
  orgId: Scalars['ID'];
  roles: Array<RoleOrganization>;
};

export type BulkUserRolesCommand = {
  userIds: Array<Scalars['ID']>;
  roles: Array<RoleUser>;
};

export type CreateAdminProductCommand = {
  id?: InputMaybe<Scalars['ID']>;
  dataType: DataProductDataType;
  openDataProductSpecification: Scalars['Json'];
  active: Scalars['Boolean'];
  version?: InputMaybe<Scalars['Int']>;
};

export type CreateDataProductPlanSubscription = {
  id?: InputMaybe<Scalars['ID']>;
  planId: Scalars['ID'];
  orgId: Scalars['ID'];
  active: Scalars['Boolean'];
  purpose: Scalars['String'];
};

export type CreateDataProductPlanSubscriptionApiKey = {
  id?: InputMaybe<Scalars['ID']>;
  subscriptionId: Scalars['ID'];
};

export type CreateOrganizationCommand = {
  id?: InputMaybe<Scalars['ID']>;
  /** Slug of the organization, only a-z, 0-9, `-` */
  orgId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateSensorRegistrationTokensCommand = {
  listId: Scalars['ID'];
  /** The orgId of the organization the registered sensors should belong to */
  orgId: Scalars['ID'];
};

export type CreateUserCommand = {
  id?: InputMaybe<Scalars['ID']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  coc: Scalars['String'];
};

export type DataMarket = {
  __typename?: 'DataMarket';
  adminProducts: Array<DataProduct>;
  adminProduct: DataProduct;
  userProducts: Array<DataProduct>;
  userProduct: DataProduct;
  products: Array<DataProduct>;
  adminSubscriptions?: Maybe<Array<DataProductPlanSubscription>>;
  subscriptions?: Maybe<Array<DataProductPlanSubscription>>;
};


export type DataMarketAdminProductArgs = {
  id: Scalars['ID'];
};


export type DataMarketUserProductArgs = {
  id: Scalars['ID'];
};

export type DataMarketMutation = {
  __typename?: 'DataMarketMutation';
  adminCreateProduct: DataProduct;
  adminUpdateProduct: DataProduct;
  adminDeleteProduct: Scalars['ID'];
  adminAcceptProduct: Scalars['ID'];
  adminRequestAmendmentProduct: Scalars['ID'];
  /** @deprecated No longer supported */
  userUpsertProduct: DataProduct;
  userCreateProduct: DataProduct;
  userUpdateProduct: DataProduct;
  userSubmitProduct: Scalars['ID'];
  userDeleteProduct: Scalars['ID'];
  adminSetHttpSource: DataProductHttpSource;
  adminDeleteHttpSource: Scalars['ID'];
  adminCreateSubscription: DataProductPlanSubscription;
  adminUpdateSubscription: DataProductPlanSubscription;
  adminDeleteSubscription: Scalars['ID'];
  deleteSubscription: Scalars['ID'];
  adminCreateApiKey: DataProductPlanSubscription;
  adminDeleteApiKey: DataProductPlanSubscription;
  createApiKey: DataProductPlanSubscription;
  deleteApiKey: DataProductPlanSubscription;
  pushProductToHubspot?: Maybe<Scalars['Boolean']>;
  reprocessDataProducts?: Maybe<Scalars['Boolean']>;
  reprocessDataProduct?: Maybe<Scalars['Boolean']>;
};


export type DataMarketMutationAdminCreateProductArgs = {
  command: CreateAdminProductCommand;
};


export type DataMarketMutationAdminUpdateProductArgs = {
  command: CreateAdminProductCommand;
};


export type DataMarketMutationAdminDeleteProductArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationAdminAcceptProductArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationAdminRequestAmendmentProductArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationUserUpsertProductArgs = {
  command: UpsertUserProductCommand;
};


export type DataMarketMutationUserCreateProductArgs = {
  command: UserCreateProductCommand;
};


export type DataMarketMutationUserUpdateProductArgs = {
  command: UpdateProductCommand;
};


export type DataMarketMutationUserSubmitProductArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationUserDeleteProductArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationAdminSetHttpSourceArgs = {
  command: SetHttpDataSourceCommand;
};


export type DataMarketMutationAdminDeleteHttpSourceArgs = {
  dataProductId: Scalars['ID'];
};


export type DataMarketMutationAdminCreateSubscriptionArgs = {
  command: CreateDataProductPlanSubscription;
};


export type DataMarketMutationAdminUpdateSubscriptionArgs = {
  command: UpdateDataProductPlanSubscription;
};


export type DataMarketMutationAdminDeleteSubscriptionArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationDeleteSubscriptionArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationAdminCreateApiKeyArgs = {
  command: CreateDataProductPlanSubscriptionApiKey;
};


export type DataMarketMutationAdminDeleteApiKeyArgs = {
  subscriptionId: Scalars['ID'];
  keyId: Scalars['ID'];
};


export type DataMarketMutationCreateApiKeyArgs = {
  command: CreateDataProductPlanSubscriptionApiKey;
};


export type DataMarketMutationDeleteApiKeyArgs = {
  subscriptionId: Scalars['ID'];
  keyId: Scalars['ID'];
};


export type DataMarketMutationPushProductToHubspotArgs = {
  id: Scalars['ID'];
};


export type DataMarketMutationReprocessDataProductArgs = {
  id: Scalars['ID'];
};

export type DataProduct = {
  __typename?: 'DataProduct';
  id: Scalars['ID'];
  dataType: DataProductDataType;
  openDataProductSpecification: Scalars['Json'];
  active: Scalars['Boolean'];
  httpSource?: Maybe<DataProductHttpSource>;
  name: Scalars['String'];
  description: Scalars['String'];
  supplier: Scalars['String'];
  state: DataProductState;
  priceplanId: Array<Scalars['String']>;
};

export enum DataProductDataType {
  Personal = 'PERSONAL',
  Common = 'COMMON'
}

export type DataProductFilter = {
  __typename?: 'DataProductFilter';
  suppliers: Array<Scalars['String']>;
  locations: Array<DataProductLocation>;
  themes: Array<DataProductTheme>;
};

export type DataProductHttpSource = {
  __typename?: 'DataProductHttpSource';
  scheme: HttpScheme;
  host: Scalars['String'];
  port: Scalars['Int'];
  basePath: Scalars['String'];
  forwardedHostname?: Maybe<Scalars['String']>;
  forwardedHeaders: Array<ForwardedHeader>;
};

export type DataProductLocation = {
  __typename?: 'DataProductLocation';
  name: Scalars['String'];
  type: DataProductLocationType;
};

export enum DataProductLocationType {
  Province = 'PROVINCE',
  City = 'CITY',
  Other = 'OTHER'
}

export type DataProductPlan = {
  __typename?: 'DataProductPlan';
  id: Scalars['ID'];
};

export type DataProductPlanSubscription = {
  __typename?: 'DataProductPlanSubscription';
  id: Scalars['ID'];
  product: DataProduct;
  planId: Scalars['ID'];
  active: Scalars['Boolean'];
  purpose: Scalars['String'];
  keys: Array<DataProductPlanSubscriptionApiKey>;
  createdAt: Scalars['Instant'];
  updatedAt: Scalars['Instant'];
};

export type DataProductPlanSubscriptionApiKey = {
  __typename?: 'DataProductPlanSubscriptionApiKey';
  id: Scalars['ID'];
  key: Scalars['String'];
  createdAt: Scalars['Instant'];
};

export type DataProductSearchQuery = {
  /** String to search for in title en description Minimum 3 characters */
  searchString: Scalars['String'];
};

export enum DataProductState {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Amend = 'AMEND',
  Accepted = 'ACCEPTED'
}

export type DataProductTheme = {
  __typename?: 'DataProductTheme';
  name: Scalars['String'];
  type: DataProductThemeType;
};

export enum DataProductThemeType {
  Dmi = 'DMI',
  Wecity = 'WECITY'
}

export type DmiOrganization = {
  __typename?: 'DmiOrganization';
  id: Scalars['ID'];
  dmiId: Scalars['String'];
  chamberOfCommerceNumber?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orgId: Scalars['String'];
};

export type DmiUser = {
  __typename?: 'DmiUser';
  id: Scalars['ID'];
  dmiId: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type ForwardedHeader = {
  __typename?: 'ForwardedHeader';
  header: Scalars['String'];
  value: Scalars['String'];
};

export type ForwardedHeaderCommand = {
  header: Scalars['String'];
  value: Scalars['String'];
};

export enum HttpScheme {
  Http = 'HTTP',
  Https = 'HTTPS'
}

export type HubspotContactList = {
  __typename?: 'HubspotContactList';
  id: Scalars['Int'];
  name: Scalars['String'];
  contactCount: Scalars['Int'];
};

export type HubspotMutation = {
  __typename?: 'HubspotMutation';
  /** Imports all the contacts from a Hubspot list as users into the given organization */
  importList: ImportHubspotListResult;
  /** Resends verification emails to all contacts in the hubspot contact list (if they exist as user) and returns a list of success/failure messages */
  sendVerificationEmails: Array<Scalars['String']>;
};


export type HubspotMutationImportListArgs = {
  command: ImportHubspotListCommand;
};


export type HubspotMutationSendVerificationEmailsArgs = {
  hubspotContactListId: Scalars['ID'];
};

export type HubspotQuery = {
  __typename?: 'HubspotQuery';
  contactLists: Array<HubspotContactList>;
};

export type ImportHubspotListCommand = {
  listId: Scalars['ID'];
  userRoles: Array<RoleUser>;
  /** The orgId of the organization the user belongs to initially */
  orgId: Scalars['ID'];
  /** The roles the user will get in the organization with orgId. If the list is empty, this and orgId are ignored */
  organizationRoles: Array<RoleOrganization>;
};

export type ImportHubspotListResult = {
  __typename?: 'ImportHubspotListResult';
  importedUsers: Array<User>;
  errorMessages: Array<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  name: Scalars['String'];
  users: Array<OrganizationUser>;
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  organization: Organization;
  user: User;
  organizationRoles: Array<RoleOrganization>;
};

export type OrganizationsMutation = {
  __typename?: 'OrganizationsMutation';
  createOrganization: Organization;
  updateOrganization: Organization;
  promoteDmiOrganization: Organization;
};


export type OrganizationsMutationCreateOrganizationArgs = {
  command: CreateOrganizationCommand;
};


export type OrganizationsMutationUpdateOrganizationArgs = {
  command: UpdateOrganizationCommand;
};


export type OrganizationsMutationPromoteDmiOrganizationArgs = {
  command: PromoteDmiOrganizationCommand;
};

export type PlatformAlert = {
  __typename?: 'PlatformAlert';
  id: Scalars['ID'];
  title: Scalars['String'];
  message: Scalars['String'];
};

export type PromoteDmiOrganizationCommand = {
  id: Scalars['ID'];
  orgId: Scalars['String'];
};

export type PromoteDmiUserCommand = {
  id: Scalars['ID'];
};

export enum RoleOrganization {
  OrgPientereTuinenManager = 'ORG_PIENTERE_TUINEN_MANAGER',
  OrgSensorRegisterManager = 'ORG_SENSOR_REGISTER_MANAGER',
  OrgSensorRegisterUser = 'ORG_SENSOR_REGISTER_USER',
  OrgSensorRegisterBulkImport = 'ORG_SENSOR_REGISTER_BULK_IMPORT',
  OrgDataMarketUser = 'ORG_DATA_MARKET_USER',
  OrgDataMarketAdmin = 'ORG_DATA_MARKET_ADMIN',
  OrgDataMarketSeller = 'ORG_DATA_MARKET_SELLER',
  OrgDataMarketBuyer = 'ORG_DATA_MARKET_BUYER',
  OrgDataMarketSubscriptionManager = 'ORG_DATA_MARKET_SUBSCRIPTION_MANAGER'
}

export enum RoleUser {
  UserGlobalAdmin = 'USER_GLOBAL_ADMIN',
  UserApiCatalogUser = 'USER_API_CATALOG_USER',
  UserPientereTuinenUser = 'USER_PIENTERE_TUINEN_USER',
  UserTreeMonitorUser = 'USER_TREE_MONITOR_USER'
}

export type SetHttpDataSourceCommand = {
  dataProductId: Scalars['ID'];
  scheme: HttpScheme;
  host: Scalars['String'];
  port: Scalars['Int'];
  basePath: Scalars['String'];
  forwardedHostname?: InputMaybe<Scalars['String']>;
  forwardedHeaders: Array<ForwardedHeaderCommand>;
};

export type SetOrganizationRolesCommand = {
  userId: Scalars['ID'];
  orgId: Scalars['ID'];
  roles: Array<RoleOrganization>;
};

export type SetUserRolesCommand = {
  userId: Scalars['ID'];
  roles: Array<RoleUser>;
};

export type UpdateDataProductPlanSubscription = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  purpose: Scalars['String'];
};

export type UpdateOrganizationCommand = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateProductCommand = {
  id: Scalars['ID'];
  openDataProductSpecification: Scalars['Json'];
  active: Scalars['Boolean'];
  priceplanId: Array<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserCommand = {
  id: Scalars['ID'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
};

export type UpsertUserProductCommand = {
  id: Scalars['ID'];
  dataType: DataProductDataType;
  openDataProductSpecification: Scalars['Json'];
  version?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['Instant'];
  updatedAt: Scalars['Instant'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  userRoles: Array<RoleUser>;
  organizations: Array<OrganizationUser>;
  /** This is an expensive call since we need to reach out to our IAM provider */
  isEmailVerified: Scalars['Boolean'];
};

export type UserCreateProductCommand = {
  id: Scalars['ID'];
  dataType: DataProductDataType;
  openDataProductSpecification: Scalars['Json'];
  version?: InputMaybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  priceplanId: Array<Scalars['String']>;
};

export type UserSearchQuery = {
  /** String to search for in firstname, lastname and email. Minimum 3 characters */
  searchString: Scalars['String'];
};

export type UserSummary = {
  __typename?: 'UserSummary';
  id: Scalars['ID'];
  createdAt: Scalars['Instant'];
  updatedAt: Scalars['Instant'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
};

export type UsersMutation = {
  __typename?: 'UsersMutation';
  createUser: User;
  updateUser: User;
  sendEmailVerification: Scalars['Boolean'];
  setUserRoles: Array<RoleUser>;
  bulkAddUserRoles: Scalars['Boolean'];
  bulkDeleteUserRoles: Scalars['Boolean'];
  setOrganizationRoles: Array<RoleOrganization>;
  bulkAddOrganizationRoles: Scalars['Boolean'];
  bulkDeleteOrganizationRoles: Scalars['Boolean'];
  promoteDmiUser: User;
};


export type UsersMutationCreateUserArgs = {
  command: CreateUserCommand;
};


export type UsersMutationUpdateUserArgs = {
  command: UpdateUserCommand;
};


export type UsersMutationSendEmailVerificationArgs = {
  id: Scalars['ID'];
};


export type UsersMutationSetUserRolesArgs = {
  command: SetUserRolesCommand;
};


export type UsersMutationBulkAddUserRolesArgs = {
  command: BulkUserRolesCommand;
};


export type UsersMutationBulkDeleteUserRolesArgs = {
  command: BulkUserRolesCommand;
};


export type UsersMutationSetOrganizationRolesArgs = {
  command: SetOrganizationRolesCommand;
};


export type UsersMutationBulkAddOrganizationRolesArgs = {
  command: BulkOrganizationRolesCommand;
};


export type UsersMutationBulkDeleteOrganizationRolesArgs = {
  command: BulkOrganizationRolesCommand;
};


export type UsersMutationPromoteDmiUserArgs = {
  command: PromoteDmiUserCommand;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  notEquals?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  set?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type StringFilter = {
  equals?: InputMaybe<Scalars['String']>;
  notEquals?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notContains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notStartsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notEndsWith?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  set?: InputMaybe<Scalars['Boolean']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notEquals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notInDateRange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  beforeDate?: InputMaybe<Scalars['String']>;
  afterDate?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Scalars['Boolean']>;
};

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc'
}

export type TimeDimension = {
  __typename?: 'TimeDimension';
  value: Scalars['DateTime'];
  second: Scalars['DateTime'];
  minute: Scalars['DateTime'];
  hour: Scalars['DateTime'];
  day: Scalars['DateTime'];
  week: Scalars['DateTime'];
  month: Scalars['DateTime'];
  quarter: Scalars['DateTime'];
  year: Scalars['DateTime'];
};

export type ApiCatalogMeteringMembers = {
  __typename?: 'ApiCatalogMeteringMembers';
  sum_calls?: Maybe<Scalars['Float']>;
  sum_bytes?: Maybe<Scalars['Float']>;
  api?: Maybe<Scalars['String']>;
  key_id?: Maybe<Scalars['String']>;
  day?: Maybe<TimeDimension>;
  user_id?: Maybe<Scalars['String']>;
};

export type ApiCatalogMeteringWhereInput = {
  AND?: InputMaybe<Array<ApiCatalogMeteringWhereInput>>;
  OR?: InputMaybe<Array<ApiCatalogMeteringWhereInput>>;
  sum_calls?: InputMaybe<FloatFilter>;
  sum_bytes?: InputMaybe<FloatFilter>;
  api?: InputMaybe<StringFilter>;
  key_id?: InputMaybe<StringFilter>;
  day?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type ApiCatalogMeteringOrderByInput = {
  sum_calls?: InputMaybe<OrderBy>;
  sum_bytes?: InputMaybe<OrderBy>;
  api?: InputMaybe<OrderBy>;
  key_id?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

export type DataMarketMeteringMembers = {
  __typename?: 'DataMarketMeteringMembers';
  sum_calls?: Maybe<Scalars['Float']>;
  sum_bytes?: Maybe<Scalars['Float']>;
  plan_id?: Maybe<Scalars['String']>;
  obscured_key?: Maybe<Scalars['String']>;
  key_id?: Maybe<Scalars['String']>;
  day?: Maybe<TimeDimension>;
  org_id?: Maybe<Scalars['String']>;
};

export type DataMarketMeteringWhereInput = {
  AND?: InputMaybe<Array<DataMarketMeteringWhereInput>>;
  OR?: InputMaybe<Array<DataMarketMeteringWhereInput>>;
  sum_calls?: InputMaybe<FloatFilter>;
  sum_bytes?: InputMaybe<FloatFilter>;
  plan_id?: InputMaybe<StringFilter>;
  obscured_key?: InputMaybe<StringFilter>;
  key_id?: InputMaybe<StringFilter>;
  day?: InputMaybe<DateTimeFilter>;
  org_id?: InputMaybe<StringFilter>;
};

export type DataMarketMeteringOrderByInput = {
  sum_calls?: InputMaybe<OrderBy>;
  sum_bytes?: InputMaybe<OrderBy>;
  plan_id?: InputMaybe<OrderBy>;
  obscured_key?: InputMaybe<OrderBy>;
  key_id?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  org_id?: InputMaybe<OrderBy>;
};

export type RootWhereInput = {
  AND?: InputMaybe<Array<RootWhereInput>>;
  OR?: InputMaybe<Array<RootWhereInput>>;
  api_catalog_metering?: InputMaybe<ApiCatalogMeteringWhereInput>;
  data_market_metering?: InputMaybe<DataMarketMeteringWhereInput>;
};

export type RootOrderByInput = {
  api_catalog_metering?: InputMaybe<ApiCatalogMeteringOrderByInput>;
  data_market_metering?: InputMaybe<DataMarketMeteringOrderByInput>;
};

export type Result = {
  __typename?: 'Result';
  api_catalog_metering: ApiCatalogMeteringMembers;
  data_market_metering: DataMarketMeteringMembers;
};


export type ResultApi_Catalog_MeteringArgs = {
  where?: InputMaybe<ApiCatalogMeteringWhereInput>;
  orderBy?: InputMaybe<ApiCatalogMeteringOrderByInput>;
};


export type ResultData_Market_MeteringArgs = {
  where?: InputMaybe<DataMarketMeteringWhereInput>;
  orderBy?: InputMaybe<DataMarketMeteringOrderByInput>;
};

export type GetApiCatalogMeteringQueryVariables = Exact<{
  where: ApiCatalogMeteringWhereInput;
}>;


export type GetApiCatalogMeteringQuery = { __typename?: 'Query', cube: Array<{ __typename?: 'Result', api_catalog_metering: { __typename?: 'ApiCatalogMeteringMembers', sum_calls?: number | null, day?: { __typename?: 'TimeDimension', day: any } | null } }> };

export type GetHubspotContactListsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHubspotContactListsQuery = { __typename?: 'Query', hubspot: { __typename?: 'HubspotQuery', contactLists: Array<{ __typename?: 'HubspotContactList', id: number, name: string, contactCount: number }> } };

export type ImportHubspotListMutationVariables = Exact<{
  command: ImportHubspotListCommand;
}>;


export type ImportHubspotListMutation = { __typename?: 'Mutation', hubspot: { __typename?: 'HubspotMutation', importList: { __typename?: 'ImportHubspotListResult', errorMessages: Array<string>, importedUsers: Array<{ __typename?: 'User', id: string, email: string, firstname: string, lastname: string }> } } };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string, orgId: string }> };

export type GetOrganizationWithUsersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationWithUsersQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, name: string, orgId: string, users: Array<{ __typename?: 'OrganizationUser', user: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, firstname: string, lastname: string, email: string } }> } };

export type CreateOrganizationMutationVariables = Exact<{
  command: CreateOrganizationCommand;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', organizations: { __typename?: 'OrganizationsMutation', createOrganization: { __typename?: 'Organization', id: string, name: string, orgId: string } } };

export type CreateGroupMutationVariables = Exact<{
  command: CreateGroupCommand;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', adminCreateGroup: { __typename?: 'GroupResult', data: { __typename?: 'Group', id: string, name?: string | null } } };

export type GetPlatformAlertsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlatformAlertsQuery = { __typename?: 'Query', platformAlerts: Array<{ __typename?: 'PlatformAlert', id: string, title: string, message: string }> };

export type GetRegisteredSensorSchemeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegisteredSensorSchemeQuery = { __typename?: 'Query', scheme?: { __typename?: 'SchemeResult', data?: { __typename?: 'Scheme', name?: string | null, propertyTypes?: Array<{ __typename?: 'PropertyType', name?: string | null, validation?: string | null, description?: string | null }> | null } | null } | null };

export type PaginateRegisteredSensorAssetsQueryVariables = Exact<{
  filterQuery?: InputMaybe<Scalars['String']>;
}>;


export type PaginateRegisteredSensorAssetsQuery = { __typename?: 'Query', registeredSensorAssets: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null }> | null, meta?: { __typename?: 'PaginationMeta', currentPage: number, lastPage?: number | null, perPage: number, from?: number | null, to?: number | null, total: number, links?: Array<{ __typename?: 'PaginationLink', url: string, active: boolean, label?: string | null }> | null } | null } };

export type SearchRegisteredSensorAssetsQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars['String']>;
}>;


export type SearchRegisteredSensorAssetsQuery = { __typename?: 'Query', searchRegisteredSensorAssets: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null }> | null, meta?: { __typename?: 'PaginationMeta', currentPage: number, lastPage?: number | null, perPage: number, from?: number | null, to?: number | null, total: number, links?: Array<{ __typename?: 'PaginationLink', url: string, active: boolean, label?: string | null }> | null } | null } };

export type GetRegisteredSensorAssetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRegisteredSensorAssetQuery = { __typename?: 'Query', registeredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, createdAt: any, updatedAt: any, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null } } };

export type CreateRegisteredSensorAssetMutationVariables = Exact<{
  command: CreateAssetCommand;
}>;


export type CreateRegisteredSensorAssetMutation = { __typename?: 'Mutation', createRegisteredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, createdAt: any } } };

export type BulkCreateRegisteredSensorAssetMutationVariables = Exact<{
  command: Array<InputMaybe<CreateAssetCommand>> | InputMaybe<CreateAssetCommand>;
}>;


export type BulkCreateRegisteredSensorAssetMutation = { __typename?: 'Mutation', bulkCreateRegisteredSensorAssets: { __typename?: 'AssetsResult', data?: Array<{ __typename?: 'Asset', id: string, name?: string | null, createdAt: any }> | null } };

export type UpdateRegisteredSensorAssetMutationVariables = Exact<{
  command: UpdateAssetCommand;
}>;


export type UpdateRegisteredSensorAssetMutation = { __typename?: 'Mutation', updateRegisteredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string, name?: string | null, properties?: Array<{ __typename?: 'Property', id: string, key?: string | null, value?: string | null, type?: string | null }> | null } } };

export type DeleteRegisteredSensorAssetMutationVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type DeleteRegisteredSensorAssetMutation = { __typename?: 'Mutation', deleteRegisteredSensorAsset: { __typename?: 'AssetResult', data: { __typename?: 'Asset', id: string } } };

export type UpdateRegisteredSensorPropertyMutationVariables = Exact<{
  command: UpdatePropertyCommand;
}>;


export type UpdateRegisteredSensorPropertyMutation = { __typename?: 'Mutation', updateRegisteredSensorProperty: { __typename?: 'PropertyResult', data: { __typename?: 'Property', id: string, key?: string | null, type?: string | null, value?: string | null } } };

export type DeleteRegisteredSensorPropertyMutationVariables = Exact<{
  command: DeletePropertyCommand;
}>;


export type DeleteRegisteredSensorPropertyMutation = { __typename?: 'Mutation', deleteRegisteredSensorProperty: { __typename?: 'PropertyResult', data: { __typename?: 'Property', id: string } } };

export type SearchUsersQueryVariables = Exact<{
  query: UserSearchQuery;
}>;


export type SearchUsersQuery = { __typename?: 'Query', userSearch: Array<{ __typename?: 'UserSummary', id: string, createdAt: any, updatedAt: any, firstname: string, lastname: string, email: string }> };

export type GetUserDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDetailsQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, createdAt: any, updatedAt: any, firstname: string, lastname: string, email: string, userRoles: Array<RoleUser>, isEmailVerified: boolean, organizations: Array<{ __typename?: 'OrganizationUser', organizationRoles: Array<RoleOrganization>, organization: { __typename?: 'Organization', orgId: string, name: string } }> } };

export type GetMyUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserRolesQuery = { __typename?: 'Query', myUserRoles: Array<RoleUser> };

export type GetMyOrganizationRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyOrganizationRolesQuery = { __typename?: 'Query', myOrganizationRoles: Array<{ __typename?: 'OrganizationUser', organizationRoles: Array<RoleOrganization>, organization: { __typename?: 'Organization', orgId: string } }> };


export const GetApiCatalogMeteringDocument = gql`
    query GetApiCatalogMetering($where: ApiCatalogMeteringWhereInput!) {
  cube(where: {api_catalog_metering: $where}) {
    api_catalog_metering(orderBy: {day: asc}) {
      sum_calls
      day {
        day
      }
    }
  }
}
    `;

/**
 * __useGetApiCatalogMeteringQuery__
 *
 * To run a query within a React component, call `useGetApiCatalogMeteringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiCatalogMeteringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiCatalogMeteringQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetApiCatalogMeteringQuery(baseOptions: Apollo.QueryHookOptions<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>(GetApiCatalogMeteringDocument, options);
      }
export function useGetApiCatalogMeteringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>(GetApiCatalogMeteringDocument, options);
        }
export type GetApiCatalogMeteringQueryHookResult = ReturnType<typeof useGetApiCatalogMeteringQuery>;
export type GetApiCatalogMeteringLazyQueryHookResult = ReturnType<typeof useGetApiCatalogMeteringLazyQuery>;
export type GetApiCatalogMeteringQueryResult = Apollo.QueryResult<GetApiCatalogMeteringQuery, GetApiCatalogMeteringQueryVariables>;
export const GetHubspotContactListsDocument = gql`
    query GetHubspotContactLists {
  hubspot {
    contactLists {
      id
      name
      contactCount
    }
  }
}
    `;

/**
 * __useGetHubspotContactListsQuery__
 *
 * To run a query within a React component, call `useGetHubspotContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubspotContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubspotContactListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHubspotContactListsQuery(baseOptions?: Apollo.QueryHookOptions<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>(GetHubspotContactListsDocument, options);
      }
export function useGetHubspotContactListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>(GetHubspotContactListsDocument, options);
        }
export type GetHubspotContactListsQueryHookResult = ReturnType<typeof useGetHubspotContactListsQuery>;
export type GetHubspotContactListsLazyQueryHookResult = ReturnType<typeof useGetHubspotContactListsLazyQuery>;
export type GetHubspotContactListsQueryResult = Apollo.QueryResult<GetHubspotContactListsQuery, GetHubspotContactListsQueryVariables>;
export const ImportHubspotListDocument = gql`
    mutation ImportHubspotList($command: ImportHubspotListCommand!) {
  hubspot {
    importList(command: $command) {
      importedUsers {
        id
        email
        firstname
        lastname
      }
      errorMessages
    }
  }
}
    `;
export type ImportHubspotListMutationFn = Apollo.MutationFunction<ImportHubspotListMutation, ImportHubspotListMutationVariables>;

/**
 * __useImportHubspotListMutation__
 *
 * To run a mutation, you first call `useImportHubspotListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportHubspotListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importHubspotListMutation, { data, loading, error }] = useImportHubspotListMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useImportHubspotListMutation(baseOptions?: Apollo.MutationHookOptions<ImportHubspotListMutation, ImportHubspotListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportHubspotListMutation, ImportHubspotListMutationVariables>(ImportHubspotListDocument, options);
      }
export type ImportHubspotListMutationHookResult = ReturnType<typeof useImportHubspotListMutation>;
export type ImportHubspotListMutationResult = Apollo.MutationResult<ImportHubspotListMutation>;
export type ImportHubspotListMutationOptions = Apollo.BaseMutationOptions<ImportHubspotListMutation, ImportHubspotListMutationVariables>;
export const GetOrganizationsDocument = gql`
    query GetOrganizations {
  organizations {
    id
    name
    orgId
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetOrganizationWithUsersDocument = gql`
    query GetOrganizationWithUsers($id: ID!) {
  organization(id: $id) {
    id
    name
    orgId
    users {
      user {
        id
        createdAt
        updatedAt
        firstname
        lastname
        email
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationWithUsersQuery__
 *
 * To run a query within a React component, call `useGetOrganizationWithUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationWithUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationWithUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationWithUsersQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>(GetOrganizationWithUsersDocument, options);
      }
export function useGetOrganizationWithUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>(GetOrganizationWithUsersDocument, options);
        }
export type GetOrganizationWithUsersQueryHookResult = ReturnType<typeof useGetOrganizationWithUsersQuery>;
export type GetOrganizationWithUsersLazyQueryHookResult = ReturnType<typeof useGetOrganizationWithUsersLazyQuery>;
export type GetOrganizationWithUsersQueryResult = Apollo.QueryResult<GetOrganizationWithUsersQuery, GetOrganizationWithUsersQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($command: CreateOrganizationCommand!) {
  organizations {
    createOrganization(command: $command) {
      id
      name
      orgId
    }
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($command: CreateGroupCommand!) {
  adminCreateGroup(input: $command) @rest(type: "GroupResult", path: "/groups", method: "POST") {
    data {
      id
      name
    }
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const GetPlatformAlertsDocument = gql`
    query GetPlatformAlerts {
  platformAlerts {
    id
    title
    message
  }
}
    `;

/**
 * __useGetPlatformAlertsQuery__
 *
 * To run a query within a React component, call `useGetPlatformAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformAlertsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>(GetPlatformAlertsDocument, options);
      }
export function useGetPlatformAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>(GetPlatformAlertsDocument, options);
        }
export type GetPlatformAlertsQueryHookResult = ReturnType<typeof useGetPlatformAlertsQuery>;
export type GetPlatformAlertsLazyQueryHookResult = ReturnType<typeof useGetPlatformAlertsLazyQuery>;
export type GetPlatformAlertsQueryResult = Apollo.QueryResult<GetPlatformAlertsQuery, GetPlatformAlertsQueryVariables>;
export const GetRegisteredSensorSchemeDocument = gql`
    query GetRegisteredSensorScheme {
  scheme @rest(type: "SchemeResult", path: "/registered-sensor/scheme", method: "GET") {
    data {
      name
      propertyTypes {
        name
        validation
        description
      }
    }
  }
}
    `;

/**
 * __useGetRegisteredSensorSchemeQuery__
 *
 * To run a query within a React component, call `useGetRegisteredSensorSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredSensorSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredSensorSchemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegisteredSensorSchemeQuery(baseOptions?: Apollo.QueryHookOptions<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>(GetRegisteredSensorSchemeDocument, options);
      }
export function useGetRegisteredSensorSchemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>(GetRegisteredSensorSchemeDocument, options);
        }
export type GetRegisteredSensorSchemeQueryHookResult = ReturnType<typeof useGetRegisteredSensorSchemeQuery>;
export type GetRegisteredSensorSchemeLazyQueryHookResult = ReturnType<typeof useGetRegisteredSensorSchemeLazyQuery>;
export type GetRegisteredSensorSchemeQueryResult = Apollo.QueryResult<GetRegisteredSensorSchemeQuery, GetRegisteredSensorSchemeQueryVariables>;
export const PaginateRegisteredSensorAssetsDocument = gql`
    query PaginateRegisteredSensorAssets($filterQuery: String) {
  registeredSensorAssets(filterQuery: $filterQuery) @rest(type: "AssetsResult", path: "/registered-sensor/assets{args.filterQuery}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
    meta {
      currentPage
      lastPage
      perPage
      from
      to
      total
      links {
        url
        active
        label
      }
    }
  }
}
    `;

/**
 * __usePaginateRegisteredSensorAssetsQuery__
 *
 * To run a query within a React component, call `usePaginateRegisteredSensorAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateRegisteredSensorAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateRegisteredSensorAssetsQuery({
 *   variables: {
 *      filterQuery: // value for 'filterQuery'
 *   },
 * });
 */
export function usePaginateRegisteredSensorAssetsQuery(baseOptions?: Apollo.QueryHookOptions<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>(PaginateRegisteredSensorAssetsDocument, options);
      }
export function usePaginateRegisteredSensorAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>(PaginateRegisteredSensorAssetsDocument, options);
        }
export type PaginateRegisteredSensorAssetsQueryHookResult = ReturnType<typeof usePaginateRegisteredSensorAssetsQuery>;
export type PaginateRegisteredSensorAssetsLazyQueryHookResult = ReturnType<typeof usePaginateRegisteredSensorAssetsLazyQuery>;
export type PaginateRegisteredSensorAssetsQueryResult = Apollo.QueryResult<PaginateRegisteredSensorAssetsQuery, PaginateRegisteredSensorAssetsQueryVariables>;
export const SearchRegisteredSensorAssetsDocument = gql`
    query SearchRegisteredSensorAssets($searchQuery: String) {
  searchRegisteredSensorAssets(searchQuery: $searchQuery) @rest(type: "AssetsResult", path: "/registered-sensor/assets/search{args.searchQuery}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
    meta {
      currentPage
      lastPage
      perPage
      from
      to
      total
      links {
        url
        active
        label
      }
    }
  }
}
    `;

/**
 * __useSearchRegisteredSensorAssetsQuery__
 *
 * To run a query within a React component, call `useSearchRegisteredSensorAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRegisteredSensorAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRegisteredSensorAssetsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useSearchRegisteredSensorAssetsQuery(baseOptions?: Apollo.QueryHookOptions<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>(SearchRegisteredSensorAssetsDocument, options);
      }
export function useSearchRegisteredSensorAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>(SearchRegisteredSensorAssetsDocument, options);
        }
export type SearchRegisteredSensorAssetsQueryHookResult = ReturnType<typeof useSearchRegisteredSensorAssetsQuery>;
export type SearchRegisteredSensorAssetsLazyQueryHookResult = ReturnType<typeof useSearchRegisteredSensorAssetsLazyQuery>;
export type SearchRegisteredSensorAssetsQueryResult = Apollo.QueryResult<SearchRegisteredSensorAssetsQuery, SearchRegisteredSensorAssetsQueryVariables>;
export const GetRegisteredSensorAssetDocument = gql`
    query GetRegisteredSensorAsset($id: ID!) {
  registeredSensorAsset(id: $id) @rest(type: "AssetResult", path: "/registered-sensor/assets/{args.id}", method: "GET") {
    data {
      id
      name
      createdAt
      updatedAt
      properties {
        id
        key
        value
        type
      }
    }
  }
}
    `;

/**
 * __useGetRegisteredSensorAssetQuery__
 *
 * To run a query within a React component, call `useGetRegisteredSensorAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredSensorAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredSensorAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegisteredSensorAssetQuery(baseOptions: Apollo.QueryHookOptions<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>(GetRegisteredSensorAssetDocument, options);
      }
export function useGetRegisteredSensorAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>(GetRegisteredSensorAssetDocument, options);
        }
export type GetRegisteredSensorAssetQueryHookResult = ReturnType<typeof useGetRegisteredSensorAssetQuery>;
export type GetRegisteredSensorAssetLazyQueryHookResult = ReturnType<typeof useGetRegisteredSensorAssetLazyQuery>;
export type GetRegisteredSensorAssetQueryResult = Apollo.QueryResult<GetRegisteredSensorAssetQuery, GetRegisteredSensorAssetQueryVariables>;
export const CreateRegisteredSensorAssetDocument = gql`
    mutation CreateRegisteredSensorAsset($command: CreateAssetCommand!) {
  createRegisteredSensorAsset(input: $command) @rest(type: "AssetResult", path: "/registered-sensor/assets", method: "POST") {
    data {
      id
      name
      createdAt
    }
  }
}
    `;
export type CreateRegisteredSensorAssetMutationFn = Apollo.MutationFunction<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>;

/**
 * __useCreateRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useCreateRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegisteredSensorAssetMutation, { data, loading, error }] = useCreateRegisteredSensorAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>(CreateRegisteredSensorAssetDocument, options);
      }
export type CreateRegisteredSensorAssetMutationHookResult = ReturnType<typeof useCreateRegisteredSensorAssetMutation>;
export type CreateRegisteredSensorAssetMutationResult = Apollo.MutationResult<CreateRegisteredSensorAssetMutation>;
export type CreateRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<CreateRegisteredSensorAssetMutation, CreateRegisteredSensorAssetMutationVariables>;
export const BulkCreateRegisteredSensorAssetDocument = gql`
    mutation BulkCreateRegisteredSensorAsset($command: [CreateAssetCommand]!) {
  bulkCreateRegisteredSensorAssets(input: $command) @rest(type: "AssetsResult", path: "/registered-sensor/assets/bulk", method: "POST") {
    data {
      id
      name
      createdAt
    }
  }
}
    `;
export type BulkCreateRegisteredSensorAssetMutationFn = Apollo.MutationFunction<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>;

/**
 * __useBulkCreateRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useBulkCreateRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateRegisteredSensorAssetMutation, { data, loading, error }] = useBulkCreateRegisteredSensorAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useBulkCreateRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>(BulkCreateRegisteredSensorAssetDocument, options);
      }
export type BulkCreateRegisteredSensorAssetMutationHookResult = ReturnType<typeof useBulkCreateRegisteredSensorAssetMutation>;
export type BulkCreateRegisteredSensorAssetMutationResult = Apollo.MutationResult<BulkCreateRegisteredSensorAssetMutation>;
export type BulkCreateRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<BulkCreateRegisteredSensorAssetMutation, BulkCreateRegisteredSensorAssetMutationVariables>;
export const UpdateRegisteredSensorAssetDocument = gql`
    mutation UpdateRegisteredSensorAsset($command: UpdateAssetCommand!) {
  updateRegisteredSensorAsset(input: $command) @rest(type: "AssetResult", path: "/registered-sensor/assets/{args.input.id}", method: "PATCH") {
    data {
      id
      name
      properties {
        id
        key
        value
        type
      }
    }
  }
}
    `;
export type UpdateRegisteredSensorAssetMutationFn = Apollo.MutationFunction<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>;

/**
 * __useUpdateRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useUpdateRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegisteredSensorAssetMutation, { data, loading, error }] = useUpdateRegisteredSensorAssetMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdateRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>(UpdateRegisteredSensorAssetDocument, options);
      }
export type UpdateRegisteredSensorAssetMutationHookResult = ReturnType<typeof useUpdateRegisteredSensorAssetMutation>;
export type UpdateRegisteredSensorAssetMutationResult = Apollo.MutationResult<UpdateRegisteredSensorAssetMutation>;
export type UpdateRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<UpdateRegisteredSensorAssetMutation, UpdateRegisteredSensorAssetMutationVariables>;
export const DeleteRegisteredSensorAssetDocument = gql`
    mutation DeleteRegisteredSensorAsset($assetId: ID!) {
  deleteRegisteredSensorAsset(assetId: $assetId) @rest(type: "AssetResult", path: "/registered-sensor/assets/{args.assetId}", method: "DELETE") {
    data {
      id
    }
  }
}
    `;
export type DeleteRegisteredSensorAssetMutationFn = Apollo.MutationFunction<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>;

/**
 * __useDeleteRegisteredSensorAssetMutation__
 *
 * To run a mutation, you first call `useDeleteRegisteredSensorAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegisteredSensorAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegisteredSensorAssetMutation, { data, loading, error }] = useDeleteRegisteredSensorAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useDeleteRegisteredSensorAssetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>(DeleteRegisteredSensorAssetDocument, options);
      }
export type DeleteRegisteredSensorAssetMutationHookResult = ReturnType<typeof useDeleteRegisteredSensorAssetMutation>;
export type DeleteRegisteredSensorAssetMutationResult = Apollo.MutationResult<DeleteRegisteredSensorAssetMutation>;
export type DeleteRegisteredSensorAssetMutationOptions = Apollo.BaseMutationOptions<DeleteRegisteredSensorAssetMutation, DeleteRegisteredSensorAssetMutationVariables>;
export const UpdateRegisteredSensorPropertyDocument = gql`
    mutation UpdateRegisteredSensorProperty($command: UpdatePropertyCommand!) {
  updateRegisteredSensorProperty(input: $command) @rest(type: "PropertyResult", path: "/registered-sensor/assets/{args.input.assetId}/properties/{args.input.propertyId}", method: "PUT") {
    data {
      id
      key
      type
      value
    }
  }
}
    `;
export type UpdateRegisteredSensorPropertyMutationFn = Apollo.MutationFunction<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>;

/**
 * __useUpdateRegisteredSensorPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateRegisteredSensorPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegisteredSensorPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegisteredSensorPropertyMutation, { data, loading, error }] = useUpdateRegisteredSensorPropertyMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useUpdateRegisteredSensorPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>(UpdateRegisteredSensorPropertyDocument, options);
      }
export type UpdateRegisteredSensorPropertyMutationHookResult = ReturnType<typeof useUpdateRegisteredSensorPropertyMutation>;
export type UpdateRegisteredSensorPropertyMutationResult = Apollo.MutationResult<UpdateRegisteredSensorPropertyMutation>;
export type UpdateRegisteredSensorPropertyMutationOptions = Apollo.BaseMutationOptions<UpdateRegisteredSensorPropertyMutation, UpdateRegisteredSensorPropertyMutationVariables>;
export const DeleteRegisteredSensorPropertyDocument = gql`
    mutation DeleteRegisteredSensorProperty($command: DeletePropertyCommand!) {
  deleteRegisteredSensorProperty(input: $command) @rest(type: "PropertyResult", path: "/registered-sensor/assets/{args.input.assetId}/properties/{args.input.propertyId}", method: "DELETE") {
    data {
      id
    }
  }
}
    `;
export type DeleteRegisteredSensorPropertyMutationFn = Apollo.MutationFunction<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>;

/**
 * __useDeleteRegisteredSensorPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteRegisteredSensorPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegisteredSensorPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegisteredSensorPropertyMutation, { data, loading, error }] = useDeleteRegisteredSensorPropertyMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useDeleteRegisteredSensorPropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>(DeleteRegisteredSensorPropertyDocument, options);
      }
export type DeleteRegisteredSensorPropertyMutationHookResult = ReturnType<typeof useDeleteRegisteredSensorPropertyMutation>;
export type DeleteRegisteredSensorPropertyMutationResult = Apollo.MutationResult<DeleteRegisteredSensorPropertyMutation>;
export type DeleteRegisteredSensorPropertyMutationOptions = Apollo.BaseMutationOptions<DeleteRegisteredSensorPropertyMutation, DeleteRegisteredSensorPropertyMutationVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($query: UserSearchQuery!) {
  userSearch(query: $query) {
    id
    createdAt
    updatedAt
    firstname
    lastname
    email
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($id: ID!) {
  user(id: $id) {
    id
    createdAt
    updatedAt
    firstname
    lastname
    email
    userRoles
    organizations {
      organization {
        orgId
        name
      }
      organizationRoles
    }
    isEmailVerified
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const GetMyUserRolesDocument = gql`
    query GetMyUserRoles {
  myUserRoles
}
    `;

/**
 * __useGetMyUserRolesQuery__
 *
 * To run a query within a React component, call `useGetMyUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>(GetMyUserRolesDocument, options);
      }
export function useGetMyUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>(GetMyUserRolesDocument, options);
        }
export type GetMyUserRolesQueryHookResult = ReturnType<typeof useGetMyUserRolesQuery>;
export type GetMyUserRolesLazyQueryHookResult = ReturnType<typeof useGetMyUserRolesLazyQuery>;
export type GetMyUserRolesQueryResult = Apollo.QueryResult<GetMyUserRolesQuery, GetMyUserRolesQueryVariables>;
export const GetMyOrganizationRolesDocument = gql`
    query GetMyOrganizationRoles {
  myOrganizationRoles {
    organization {
      orgId
    }
    organizationRoles
  }
}
    `;

/**
 * __useGetMyOrganizationRolesQuery__
 *
 * To run a query within a React component, call `useGetMyOrganizationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrganizationRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrganizationRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrganizationRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>(GetMyOrganizationRolesDocument, options);
      }
export function useGetMyOrganizationRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>(GetMyOrganizationRolesDocument, options);
        }
export type GetMyOrganizationRolesQueryHookResult = ReturnType<typeof useGetMyOrganizationRolesQuery>;
export type GetMyOrganizationRolesLazyQueryHookResult = ReturnType<typeof useGetMyOrganizationRolesLazyQuery>;
export type GetMyOrganizationRolesQueryResult = Apollo.QueryResult<GetMyOrganizationRolesQuery, GetMyOrganizationRolesQueryVariables>;