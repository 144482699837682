import { TFunction } from 'i18next';
import { Asset, Property } from './generated/gql/types';
import { DropdownOption } from './components/modules/registered-sensor/Dropdown';

export enum RegisteredSensorAssetProperty {
  Goal = 'goal',
  Title = 'title',
  Coordinates = 'coordinates',
  SensorType = 'sensor_type',
  Theme = 'theme',
  ProcessesPersonalData = 'processes_personal_data',
  IsMobile = 'is_mobile',
  Status = 'status',
  ValidUntil = 'valid_until',
  LegislationUrl = 'legislation_url',
  ContactName = 'contact_name',
  ContactEmail = 'contact_email',
  Organization = 'operating_organization',
  Location = 'location',
  SensorReferenceId = 'sensor_reference_id',
}

export function getProperty(
  asset: Asset,
  property: RegisteredSensorAssetProperty,
): Property | undefined {
  return asset.properties?.find((p) => p.key === property);
}

export function validationStringToOptionList(validationString: string, t?: TFunction): DropdownOption[] {
  return validationString.replace('in:', '').split(',').map((item) => (
    {
      name: t ? t(item.replaceAll('_', ' ')) : item.replaceAll('_', ' '),
      value: item,
    }
  ));
}
