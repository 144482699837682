export const LandingRoute = () => '/';
export const LoadingRoute = () => '/loading';
export const DashboardRoute = () => '/dashboard';
export const ApiSubscriptionsRoute = () => '/api-subscriptions';

export const SRRouteDashboard = () => '/registered-sensor';
export const SRRoute = (orgId?: string) => (orgId ? `/registered-sensor/${orgId}` : '/registered-sensor/:orgId');
export const SRAssetCreateRoute = (orgId? :string) => (orgId ? `/registered-sensor/${orgId}/asset/` : '/registered-sensor/:orgId/asset');
export const SRAssetImportRoute = (orgId? :string) => (orgId ? `/registered-sensor/${orgId}/asset-import/` : '/registered-sensor/:orgId/asset-import');
export const SRAssetRoute = (orgId?: string, assetId?: string) => ((assetId && orgId) ? `/registered-sensor/${orgId}/asset/${assetId}` : '/registered-sensor/:orgId/asset/:assetId');

// admin
export const ImportHubspotContactListRoute = () => '/admin/hubspot-import';
export const CreateSensorRegistrationTokensRoute = () => '/admin/create-sensor-registration-tokens';
export const SendVerificationEmailRoute = () => '/admin/send-verification-email';
